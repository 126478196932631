<template>
  <a-icon
    class="trigger"
    :type="$ax.config.collapsed ? 'menu-unfold' : 'menu-fold'"
    @click="$ax.config.collapsed = !$ax.config.collapsed"
  />
</template>

<script>
export default {
  name: 'Trigger'
}
</script>

<style lang="less" scoped>
  .trigger{
    cursor: pointer;
    transition: color 0.3s;
    font-size: 16px;
  }
</style>
